import Api from "../apis/Api"

export const OpenWPLoginModalMixin = {
    data: function () {
        return {
            checkingWpLogin: false
        }
    },
    methods: {
        openWPLoginModal: function () {
            this.checkingWpLogin = true;
            let instance = this.instance;
            if(!instance) {
                instance = this.item;
            }
            Api.get(`/instances/${instance.id}/wordpress/users?role=administrator`)
                .then((response) => {
                    let options = [];
                    for (let user of response.data.data) {
                        options.push({ label: `${user.display_name} - ${user.email}`, value: user.id });
                    }
                    this.$emit("action-button-wplogin", {
                        application: instance,
                        options: options,
                    });
                })
                .catch((error) => {
                    this.$store.dispatch("addAlert", {
                        success: false,
                        errorMessage: Api.getErrorMessage(error),
                    });
                })
                .finally(() => {
                    this.checkingWpLogin = false;
                }); 
        },
    }
}

export const OpenUpdatesModalMixin = {
    data: function () {
        return {
            checkingUpdates: false
        }
    },
    methods: {
        openUpdateModal: function () {
            this.checkingUpdates = true;
            let instance = this.instance;
            if(!instance) {
                instance = this.item;
            }
            Api.get(`/instances/${instance.id}/wordpress/updates`)
                .then((response) => {
                    this.$emit("action-button-update", {
                        application: instance,
                        availableUpdates: response.data.data.available_updates,
                    });
                })
                .catch((error) => {
                    const errorMsg = Api.getErrorMessage(error);
                    this.$store.dispatch("addAlert", {
                        success: true,
                        successMessage: errorMsg,
                    });
                })
                .finally(() => {
                    this.$nextTick(() => {
                        this.checkingUpdates = false;
                    });
                });
        },
    }
}

export const OpenPushToLiveModalMixin = {
    data: function () {
        return {
            loadingPushToLive: false
        }
    },
    methods: {
        openPushToLiveModal: function () {
            this.loadingPushToLive = true;
            this.$emit("action-button-push-to-live", {
                loadingState: this.loadingPushToLive
            });

            Api.get(`/instances/${this.instance.id}/push-to-live`)
                .then((response) => {
                    this.$emit("action-button-push-to-live", {
                        application: this.instance,
                        databaseDiff: response.data.data,
                    });
                })
                .catch((error) => {
                    const errorMsg = Api.getErrorMessage(error);
                    this.$store.dispatch("addAlert", {
                        success: false,
                        errorMessage: errorMsg,
                    });
                })
                .finally(() => {
                    this.$nextTick(() => {
                        this.loadingPushToLive = false;
                    });
                });
        },
    }
}

export const OpenPushToStagingModalMixin = {
    data: function () {
        return {
            loadingPushToStaging: false
        }
    },
    methods: {
        openPushToStagingModal: function () {
            this.loadingPushToStaging = true;
            this.$emit("action-button-push-to-staging", {
                loadingState: this.loadingPushToStaging
            });

            Api.get(`/instances/${this.instance.id}/push-to-staging`)
              .then((response) => {
                  this.$emit("action-button-push-to-staging", {
                      application: this.instance,
                      databaseDiff: response.data.data,
                  });
              })
              .catch((error) => {
                  const errorMsg = Api.getErrorMessage(error);
                  this.$store.dispatch("addAlert", {
                      success: false,
                      errorMessage: errorMsg,
                  });
              })
              .finally(() => {
                  this.$nextTick(() => {
                      this.loadingPushToStaging = false;
                  });
              });
        },
    }
}