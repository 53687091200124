import Vue from 'vue'
import Vuex from 'vuex'

import InstanceModule from "./InstanceModule"
import ThemeModule from "./ThemeModule"
import AlertsModule from "./AlertsModule"
import LabelsModule from "./LabelsModule"
import NotificationsModule from "./NotificationsModule"
import InstallationModule from "./InstallationModule"

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    InstanceModule,
    ThemeModule,
    AlertsModule,
    LabelsModule,
    NotificationsModule,
    InstallationModule,
  },
  state: {
    bodyScrollLock: false,
    demoMode: process.env.VUE_APP_DEMO_MODE == 'true' ? true : false,
    apiCache: {},
    home: {},
    services: {},
    env: {},
  },
  mutations: {
    bodyScrollChange: function (state, payload) {
      state.bodyScrollLock = payload
    },
    setApiCache: function (state, payload) {
      state.apiCache[payload.url] = { result: payload.result, time: payload.time };
    },
    unsetApiCache: function (state, payload) {
      state.apiCache[payload.url] = undefined;
    },
    clearApiCache: function (state) {
      state.apiCache = {};
    },
    setHome: function (state, payload) {
      state.home = payload;
    },
    setServices: function (state, payload) {
      state.services = {
        data: payload,
        hasActiveService: () => {
          for (const service of payload) {
            if (service.status == 'active') {
              return true;
            }
          }
          return false;
        },
        hasLimitReached: () => {
          for (let service of payload) {
            if (service.status == 'active' && service.instance_count < service.instance_limit) {
              return false;
            }
          }
          return true;
        },
        totalInstanceCount: () => {
          let count = 0;

          if (!payload.length) return count;

          for (let service of payload) {
            count += service.instance_count;
          }

          return count;
        },
        totalInstancesLimit: () => {
          let limit = 0;
          for (let service of payload) {
            if (service.status == 'active') {
              limit += service.instance_limit;
            }
          }
          return limit;
        },
        hasAvailableUpgrade: () => {
          for (let service of payload) {
            if (service.plan_upgrade_link) {
              return true;
            }
          }
          return false;
        },
        allServicesHaveChooseTheme: () => {
          for (let service of payload) {
            if (service.install_method == "standard" && !service.choose_theme) {
              return false;
            }
            if (service.install_method == "quick" && service.plan_default_theme) {
              return false;
            }
            if (service.install_method == "quick" && service.is_installed_from_template && !service.quick_allow_to_choose_themes) {
              return false;
            }
            if (service.install_method == "super_quick") {
              return false;
            }
          }
          return true;
        },
        getServicesWithChooseTheme: () => {
          let services = [];
          for (let service of payload) {
            if (service.status != "active") {
              continue;
            }
            if (service.install_method == "quick" && service.is_installed_from_template && !service.quick_allow_to_choose_themes) {
              continue;
            }
            if (service.install_method == "quick" || service.choose_theme) {
              services.push(service);
            }
          }
          return services;
        },
        hasServiceWithoutChooseTheme: () => {
          for (let service of payload) {
            if (service.status != "active") {
              continue;
            }
            if (service.install_method == "standard" && service.choose_theme == false) {
              return true;
            }
            if (service.install_method == "quick" && service.plan_default_theme) {
              return true;
            }
            if (service.install_method == "quick" && service.is_installed_from_template && !service.quick_allow_to_choose_themes) {
              return true;
            }
            if (service.install_method == "super_quick") {
              return true;
            }
          }
          return false;
        },
        getActiveServices: () => {
          let services = [];
          for (let service of payload) {
            if (service.status != "active") {
              continue;
            }
            services.push(service);
          }
          return services;
        },
        hasOneActiveService: () => {
          let services = [];
          for (let service of payload) {
            if (service.status != "active") {
              continue;
            }
            services.push(service);
          }
          return services.length === 1;
        },
        getTrialService: () => {
          for (let service of payload) {
            if (service.is_trial) {
              return service;
            }
          }
          return null;
        }
      };
    },
    setEnv: function (state, payload) {
      state.env = payload;
    },
  },
  actions: {
    lockBodyScroll: function () {
      document.querySelector("html").classList.add('scrollLock');

      store.commit('bodyScrollChange', true)
    },
    unlockBodyScroll: function () {
      document.querySelector("html").classList.remove('scrollLock');

      store.commit('bodyScrollChange', false)
    },
    setApiCache: function (_, payload) {
      store.commit('setApiCache', payload);
    },
    unsetApiCache: function (_, payload) {
      store.commit('unsetApiCache', payload);
    },
    clearApiCache: function (_, payload) {
      store.commit('clearApiCache', payload);
    },
  }
})

export default store