<template>
  <div v-if="trialService" class="warning">
    {{ $t("Your Trial Expires") }} {{ trialExpiryAt }}.
    <v-btn
      v-if="trialUpgradeUrl"
      :href="trialUpgradeUrl"
      target="_blank"
      x-small
      color="warning"
    >
      {{ $t("Upgrade Now") }}
    </v-btn>
  </div>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      trialService: null,
    };
  },
  computed: {
    trialExpiryAt() {
      if (this.trialService?.is_trial) {
        return moment(this.trialService.trial_expiry_at).fromNow();
      }
      return "";
    },
    trialUpgradeUrl() {
      if (this.trialService?.is_trial) {
        return this.trialService.plan_upgrade_link;
      }
      return "";
    },
  },
  mounted() {
    this.trialService = this.$store.state.services.getTrialService();
  },
};
</script>
