<template>
  <div
    class="no-data"
    :style="{
      'background-image':
        'url(' +
        require('@/assets/illustrations/instances-background.png') +
        ')',
    }"
  >
    <v-container>
      <div class="nodata-container w-100 d-flex align-center justify-center">
        <v-card elevation="4" class="px-12 py-12">
          <div class="nodata-container__box">
            <div class="nodata-container__img">
              <wordpress-illustration />
            </div>

            <p
              class="
                mb-0
                primary--text
                text--darken-1
                p-2
                font-weight-bold
                mt-5
              "
            >
              {{ $t('general.greeting', {name: name}) }}
            </p>
            <h1 class="nodata-container__title mt-1">
              {{ $t('message.emptyTable.instances.title') }}
            </h1>

            <p class="nodata-container__message mb-0 p-1 mt-4">
              {{ $t('message.emptyTable.instances.subtitle') }}
            </p>

            <v-btn
              @click="loadServices"
              :loading="loadingServices"
              color="primary"
              x-large
              elevation="0"
              class="mt-8"
            >
              <v-icon color="white">$plus</v-icon>
              <span class="font-weight-600">{{ $t('button.instance.create') }}</span>
            </v-btn>
          </div>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import Api from "@/apis/Api";
import WordpressIllustration from "../illustrations/wordpress-illustration.vue";

export default {
  components: { WordpressIllustration },
  data() {
    return {
      loadingServices: false,
    };
  },
  computed: {
    name() {
      return this.$store.state.home.user.name || this.$t('general.adventurer');
    },
  },
  methods: {
    loadServices() {
      this.loadingServices = true;
      Api.services().then(() => {
        this.loadingServices = false;
        this.startInstallationProcess();
      });
    },
    startInstallationProcess: function () {
      if (!this.$store.state.services.hasActiveService()) {
        this.$store.dispatch("displayZeroDataModal");
        return;
      }

      this.$store.dispatch("startInstallationProcess");
    },
  },
};
</script>

<style lang="scss" scoped>
.no-data {
  background-position: center -133px;
  margin-left: -24px;
  margin-right: -24px;
}
.container {
  height: calc(100vh - 88px);
  padding: 0;
  margin-top: auto;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: -60px;
}
.nodata-container {
  height: 100%;
  padding-top: 75px;
  .v-card {
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 12px;
  }
  &__message {
    color: map-get($text, base);
  }
  &__box {
    margin: auto;
    max-width: 560px;
    text-align: center;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  &__img {
    max-height: 64px;
  }
}
</style>