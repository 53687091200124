<template>
  <div class="table-item item--visitors" :class="main ? 'group-start': ''">
    <template v-if="main">
      <div class="item item--main">
        <p class="item__value" v-if="item.hasActionInProgress()">
          <v-icon class="text--gray-lighten1" :size="20">$loader</v-icon>
        </p>
        <p class="item__value" v-else-if="item.info.visitors.current === '-' || item.info.visitors.current === '0'">
          <span class="p-4 text--gray-lighten1">{{ $t('general.nodata') }}</span>
        </p>
        <p class="item__value" v-else>
          <span class="item__value-current p-2">
            {{ item.info.visitors.current }}
          </span>
          <delta-indicator :value="item.info.visitors.delta" />
        </p>
      </div>
    </template>
    <template v-else>
      <div class="item">
        <span class="p-4 text--gray-lighten1">{{ $t('general.nodata') }}</span>
      </div>
    </template>
  </div>
</template>

<script>
import DeltaIndicator from "../../DeltaIndicator.vue";

export default {
  components: {
    DeltaIndicator,
  },
  props: {
    item: {
      type: Object,
    },
    main: {
      type: Boolean,
      default: false
    }
  },
};
</script>