import Api from "@/apis/Api";
import Auth from "../apis/Auth";

import ActionModalMixin from "./ActionModalMixin"
import DomainValidationMixin from "./DomainValidationMixin";

export default {
  mixins: [ActionModalMixin, DomainValidationMixin],
  data() {
    return {
      modalRender: 0,
      modalOptions: {}
    };
  },
  methods: {
    showUnsuspendInstance(url) {
      this.resetModal();
      this.modalOptions.title = this.$t("heading.instance.unsuspend.title");
      this.modalOptions.type = 'suspend';
      this.modalOptions.message = this.$t("heading.instance.unsuspend.message");
      this.modalOptions.icon = "$suspended"
      this.modalOptions.color = 'sand'
      this.modalOptions.persistent = true;
      this.modalOptions.formValues = {}

      this.modalOptions.buttons.unshift({
        label: this.$t("button.unsuspend"),
        color: 'sand darken-1',
        onclick: () => {
          this.unsuspendInstance(url)
        }
      });

      this.modalOptions.open = true;
    },
    unsuspendInstance(url) {
      window.open(url, '_blank');


    },
    // SSO ACTION
    showSsoModal({ application, options }) {
      if (options.length === 1) {
        this.ssoModalSingleUser({ application, options });
        return;
      }
      this.resetModal();
      this.modalOptions.title = this.$t("instance.modal.ssoLogin.title");
      this.modalOptions.icon = "$wordpress"
      this.modalOptions.color = 'info'
      this.modalOptions.application = application
      this.modalOptions.persistent = true;
      this.modalOptions.formValues = {}

      this.modalOptions.buttons.unshift({
        label: this.$t("button.generateSsoLink"),
        color: 'primary',
        onclick: modal => {
          modal.$refs.form.validate() &&
            this.ssoRedirect(application, modal.formValues);
        }
      });

      this.modalOptions.formValues.user = options[0].value;
      this.modalOptions.formFields = [
        {
          label: this.$t("form.label.loginAs"),
          tooltip: "tooltip.ssoLogin.field.user",
          name: "user",
          type: "select",
          options: options,
        },
      ];
      this.modalOptions.submitting = false;

      this.modalOptions.open = true;
    },
    ssoModalSingleUser({ application, options }) {
      this.resetModal();
      this.modalOptions.title = this.$t("heading.instance.ssoSingleLogin.title");
      this.modalOptions.icon = "$wordpress";
      this.modalOptions.color = 'info';
      this.modalOptions.message = this.$t("heading.instance.ssoSingleLogin.message");
      this.modalOptions.application = application
      this.modalOptions.persistent = true;
      this.ssoRedirect(application, { user: options[0].value });
      this.modalOptions.open = true;
    },
    ssoRedirect(application, data) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      Api.post(
        `/instances/${application.id}/sso-url`,
        data
      ).then(response => {
        this.modalOptions.formFields = [];
        this.modalOptions.message = this.$t("notification.instance.modal.ssoLogin.success");

        let newWindow = window.open(response.data.data.sso_url, '_blank');

        if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {
          this.modalOptions.buttons = [{
            label: this.$t("button.ssoLogin"),
            color: 'primary',
            onclick: () => {
              window.open(response.data.data.sso_url, '_blank');
              this.modalClose();
            }
          }];
        } else {
          this.modalOptions.open = false;
        }


      }).catch(error => {
        this.modalOptions.submittingError = Api.getErrorMessage(error);
        this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: Api.getErrorMessage(error),
        });
      }).finally(() => {
        this.modalOptions.persistent = false;
        this.modalOptions.submitting = false;
      });
    },
    showStagingModal(application) {
      this.$refs.createStagingModal.open(application);
    },
    showRetryStagingModal(application) {
      this.$refs.createStagingModal.open(application.parentInstance, application.id);
    },
    cancelStaging(stagingInstance) {
      this.$emit('staging-cancel', stagingInstance);
      Api.delete(
        `/instances/${stagingInstance.id}`,
        {
          data: {
            remove_data: true,
            remove_database: true,
          }
        }).then(() => {
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.$t('notification.instance.modal.staging.cancel.success')
          });
          this.$emit('staging-deleted', stagingInstance);
        }).catch(error => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
          this.$emit('staging-cancel-failed', stagingInstance);
        }).finally(() => {
        });
    },
    // UPDATE ACTION
    showUpdateModal({ application, availableUpdates }) {
      this.resetModal();

      this.modalOptions.title = this.$t("heading.instance.update.title");
      this.modalOptions.icon = '$update'

      this.modalOptions.open = true;

      this.modalOptions.buttons.unshift({
        label: this.$t("button.update"),
        color: 'primary',
        onclick: modal => {
          modal.$refs.form.validate() &&
            this.updateInstance(application, modal.formValues);
        }
      });

      if (!availableUpdates.length) {
        this.$store.dispatch("addAlert", {
          success: true,
          successMessage: this.$t('message.noUpdates')
        });

        this.modalClose();
      } else {
        let options = [];
        for (let version of availableUpdates) {
          options.push({ label: version, value: version });
        }
        this.modalOptions.formFields = [
          {
            label: this.$t("form.label.version"),
            tooltip: "Version",
            name: "version",
            type: "select",
            options: options,
          },
          {
            label: this.$t("form.label.createBackup"),
            tooltip: "Create Backup",
            name: "create_backup",
            type: "checkbox",
          },
        ];

        this.modalOptions.formValues = { version: options[0].value };
      }

    },
    updateInstance(application, formValues) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      Api.put(
        `/instances/${application.id}/wordpress/update`,
        formValues
      ).then(() => {
        this.$store.dispatch("addAlert", {
          success: true,
          successMessage: this.$t('notification.instance.modal.update.success')
        });
        this.modalClose();
        this.$emit('instance-update-in-progress', application.id);
      }).catch(error => {
        this.modalOptions.submittingError = Api.getErrorMessage(error);
        this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: Api.getErrorMessage(error),
        });
      }).finally(() => {
        this.modalOptions.persistent = false;
        this.modalOptions.submitting = false;
      });
    },
    // CLEAR CACHE ACTION
    showClearCacheModal(application) {
      this.resetModal();
      this.modalOptions.title = this.$t("heading.instance.clearCache.title");
      this.modalOptions.message = this.$t("heading.instance.clearCache.message", { count: 1 })
      this.modalOptions.buttons.unshift({
        label: this.$t("button.clearCache"),
        color: 'primary',
        onclick: () => {
          this.clearCache(application.id);
        }
      });
      this.modalOptions.open = true;
    },
    clearCache(application) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      let appid = ''
      if (typeof application == 'number') {
        appid = application
      } else {
        appid = application.id
      }

      Api.put(`/instances/${appid}/clear-cache`).then(() => {
        this.$store.dispatch("addAlert", {
          success: true,
          successMessage: this.$t("notification.instance.modal.clearCache.success")
        });

      }).catch(error => {
        this.modalOptions.submittingError = Api.getErrorMessage(error);
        this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: Api.getErrorMessage(error),
        });
      }).finally(() => {
        this.modalOptions.persistent = false;
        this.modalOptions.submitting = false;
        this.modalOptions.open = false;
      });
    },
    showMassClearCacheModal() {
      this.resetModal();
      this.modalOptions.title = this.$t("heading.instance.clearCache.title");
      this.modalOptions.message = this.$t("heading.instance.clearCache.message", { count: 2 })
      this.modalOptions.icon = "$restore"

      const affectedItems = this.checkedItems.map(checkedItem => {
        return this.items.find(item => (item.id === checkedItem))
      })

      this.modalOptions.buttons.unshift({
        label: this.$t("button.clearCache"),
        color: 'primary',
        onclick: () => {
          this.massClearCache(affectedItems);
        }
      });
      this.modalOptions.open = true;
    },
    massClearCache(items) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      const clearAll = items.map(item => {
        return Api.put(`/instances/${item.id}/clear-cache`)
      })

      Promise.all(clearAll).then(() => {
        this.$store.dispatch("addAlert", {
          success: true,
          successMessage: this.$t("notification.instance.modal.clearCache.success")
        });
      }).catch(error => {
        this.modalOptions.submittingError = Api.getErrorMessage(error);
        this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: Api.getErrorMessage(error),
        });
      }).finally(() => {
        this.modalOptions.persistent = false;
        this.modalOptions.submitting = false;
        this.modalOptions.open = false;
      });
    },
    // MASS DELETE ACTION

    showMassDeleteModal(applications) {
      this.resetModal();
      this.modalOptions.color = 'error'
      this.modalOptions.title = this.$t("heading.instance.delete.title", { count: applications.length });
      this.modalOptions.message = this.$t("heading.instance.delete.message", { count: applications.length });
      this.modalOptions.icon = "$alertwarning"

      this.modalOptions.buttons.unshift({
        label: this.$t("button.deleteInstance", { count: applications.length }),
        color: 'error',
        onclick: (modal) => {
          this.massDeleteInstance(applications, modal.formValues);
        }
      });

      this.modalOptions.formFields = [
        {
          label: this.$t("form.label.removeData"),
          name: "remove_data",
          type: "checkbox",
        },
        {
          label: this.$t("form.label.removeDatabase"),
          name: "remove_database",
          type: "checkbox",
        },
        {
          message: `<b>${this.$t("message.confirmAction")}</b>`,
          label: this.$t('form.confirmDelete.instance', { count: applications.length }),
          name: "confirm",
          type: "checkbox",
          required: true
        },
      ];
      this.modalOptions.open = true;
    },
    massDeleteInstance(applications, data) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;

      Promise.all(applications.map(item => {
        return Api.delete(`/instances/${item.id}`,
          { data: data })
      })).then(() => {
        Auth.home(true);
        this.$store.dispatch("addAlert", {
          success: true,
          successMessage: this.$t("notification.instance.modal.delete.success", { count: applications.length })
        });
        this.modalClose();
        this.$emit('instance-deleted', applications);

        this.checkedItems = []
      }).catch(error => {
        this.modalOptions.submittingError = Api.getErrorMessage(error);
        this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: Api.getErrorMessage(error),
        });
      }).finally(() => {
        this.modalOptions.persistent = false;
        this.modalOptions.submitting = false;
      });
    },


    // DELETE ACTION
    showDeleteModal(application) {
      this.resetModal();
      this.modalOptions.color = 'error'
      this.modalOptions.application = application
      this.modalOptions.title = this.$t("heading.instance.delete.title", { count: 1 });
      this.modalOptions.message = this.$t("heading.instance.delete.message", { count: 1 });
      this.modalOptions.icon = "$alertwarning"

      this.modalOptions.buttons.unshift({
        label: this.$t("button.deleteInstance", { count: 1 }),
        color: 'error',
        onclick: (modal) => {
          this.deleteInstance(application, modal.formValues);
        }
      });

      this.modalOptions.formFields = [
        {
          label: this.$t("form.label.removeData"),
          name: "remove_data",
          type: "checkbox",
        },
        {
          label: this.$t("form.label.removeDatabase"),
          name: "remove_database",
          type: "checkbox",
        },
        {
          message: `<b>${this.$t('message.confirmAction')}</b>`,
          label: this.$t('form.confirmDelete.instance', { count: 1 }),
          name: "confirm",
          type: "checkbox",
          required: true
        },
      ];
      this.modalOptions.open = true;
    },
    deleteInstance(application, data) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;

      Api.delete(
        `/instances/${application.id}`,
        { data: data }).then(() => {
          Auth.home(true);
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.$t("notification.instance.modal.delete.success", { count: 1 })
          });
          this.modalClose();
          this.$emit('instance-deleted', application);
        }).catch(error => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        }).finally(() => {
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },

    // INSTALL WOOCOMMERCE ACTION
    showInstallWoocommerceModal(application) {
      this.resetModal();
      this.modalOptions.title = this.$t("heading.instance.woocommerce.title");
      this.modalOptions.message = this.$t("heading.instance.woocommerce.message");
      this.modalOptions.buttons.unshift({
        label: this.$t("button.install"),
        color: 'primary',
        onclick: () => {
          this.installWoocommerce(application);
        }
      });
      this.modalOptions.open = true;
    },
    installWoocommerce(application) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      Api.post(
        `/instances/${application.id}/wordpress/plugins/new/woocommerce/install`
      ).then((response) => {
        this.$store.dispatch("addAlert", {
          success: true,
          successMessage: this.$t("notification.instance.modal.woocommerce.install.success")
        });
        application.setPluginsData(response.data.data);
        this.modalClose();
      }).catch(error => {
        this.modalOptions.submittingError = Api.getErrorMessage(error);
        this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: Api.getErrorMessage(error),
        });
      }).finally(() => {
        this.modalOptions.persistent = false;
        this.modalOptions.submitting = false;
      });
    },

    // PUSH TO LIVE ACTION
    showPushToLiveModal({ application, databaseDiff, loadingState }) {
      this.resetModal();
      this.modalOptions.title = this.$t("heading.instance.pushToLive.title");
      this.modalOptions.icon = '$send'
      this.modalOptions.open = true;
      if(loadingState){
        this.modalOptions.customClass = "hide-cancel";
        this.modalOptions.messageLoader = true;
        this.modalOptions.messageTitle = this.$t("heading.instance.pushToLoadingState.messageTitle");
        this.modalOptions.messageTitleClass = 'text-center'
        this.modalOptions.message = this.$t("heading.instance.pushToLoadingState.message");
        this.modalOptions.messageClass = 'text-center'
        this.modalOptions.buttons.unshift({
          label: this.$t("button.close"),
          type: "cancel",
          color: "normal",
          onclick: modal => {
            modal.$emit('modal-close');
          }
        });
      }else{
        this.modalOptions.buttons.unshift({
          label: this.$t("button.pushToLive"),
          color: 'primary',
          onclick: modal => {
            modal.$refs.form.validate() &&
              this.pushToLive(application, modal.formValues);
          }
        });
        let structureChanges = [];
        for (let table of databaseDiff.tables_structure_diff) {
          structureChanges.push({ label: table, value: table });
        }
        let dataChanges = [];
        for (let table of databaseDiff.tables_data_diff) {
          dataChanges.push({ label: table, value: table });
        }
        this.modalOptions.formFields = [
          {
            label: this.$t("form.label.overwriteFiles"),
            name: "overwrite_files",
            type: "checkbox",
          },
          {
            label: this.$t("form.label.pushDatabase"),
            name: "push_db",
            type: "checkbox",
          },
          {
            label: this.$t("form.label.structuralChangeTables"),
            name: "structural_change_tables",
            tooltip: "tooltip.instance.pushToLive.structuralChangeTables",
            type: "select",
            multiple: true,
            options: structureChanges,
          },
          {
            label: this.$t("form.label.dataChangeTables"),
            name: "datachange_tables",
            tooltip: "tooltip.instance.pushToLive.dataChangeTables",
            type: "select",
            multiple: true,
            options: dataChanges,
          },
        ];
      }

    },
    pushToLive(application, formValues) {
      if (formValues.overwrite_files === null) {
        formValues.overwrite_files = false;
      }
      if (formValues.push_db === null) {
        formValues.push_db = false;
      }
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      Api.put(
        `/instances/${application.id}/push-to-live`,
        formValues
      ).then(() => {
        this.$store.dispatch("addAlert", {
          success: true,
          successMessage: this.$t("notification.instance.modal.pushToLive.success")
        });
        this.modalClose();
        this.$emit('instance-push-to-live-in-progress', application);
      }).catch(error => {
        this.modalOptions.submittingError = Api.getErrorMessage(error);
        this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: Api.getErrorMessage(error),
        });
      }).finally(() => {
        this.modalOptions.persistent = false;
        this.modalOptions.submitting = false;
      });
    },

    //COPY INSTANCE
    showCopyInstanceModal(application) {
      this.resetModal();
      this.modalOptions.customClass = "copy-instance-modal";
      this.modalOptions.title = this.$t("heading.instance.advanced.copyInstance.copy.title");
      this.modalOptions.message = this.$t("heading.instance.advanced.copyInstance.modal.selectService.message");
      this.modalOptions.icon = "$copy";
      this.modalOptions.application = application

      const data = this.$store.state.services.data.map(service => {
        let text = service.plan_name;
        text += ' (' + this.$t("heading.onboarding.chooseProduct.product.instancesLimit", {
          count: service.instance_count,
          limit: service.instance_limit,
        }) + ')'
        if (service.hosting_accounts.length) {
          text += ' - ' + service.hosting_accounts[0].domain;
        }
        return {
          label: text,
          value: service.id
        }
      });

      let selectedService = data[0].value;

      this.modalOptions.formFields = [
        {
          name: "service_id",
          type: "select",
          customClass: "select-truncate-100",
          options: data,
        },
      ];

      this.modalOptions.buttons.unshift({
        label: this.$t("button.copyInstance"),
        color: "primary",
        onclick: (item) => {
          const service = this.$store.state.services.data.find(service => service.id === item.formValues.service_id);
          if (service.onboarding.user_configurable_options.length > 0) {
            this.selectUserConfigurableOptions(application, service);
            return;
          }

          this.copyInstance(application, item);
        },
      });

      this.modalOptions.item = {
        service_id: selectedService,
      };

      this.modalOptions.open = true;
    },
    selectUserConfigurableOptions(application, service) {
      this.resetModal();
      this.modalOptions.title = this.$t("heading.instance.advanced.copyInstance.copy.title");
      this.modalOptions.icon = "$copy";
      this.modalOptions.application = application;

      this.modalOptions.buttons.unshift({
        label: this.$t("button.copyInstance"),
        color: "primary",
        onclick: (item) => {
          this.copyInstance(application, item);
        },
      });

      this.modalOptions.item = {
        service_id: service.id,
      };

      service.onboarding.user_configurable_options.forEach((option) => {
        this.modalOptions.formFields.push(
          {
            label: this.$t(`form.label.${option.name}`),
            name: option.name,
            type: option.type,
            options: option.options.map(item => {
              return {
                label: item.text,
                value: item.value
              }
            }),
          }
        );
        this.modalOptions.item[option.name] = option.value;
      })

      this.modalOptions.open = true;
    },
    copyInstance(application, item) {
      this.modalOptions.submitting = true;

      Api.post(`/instances/${application.id}/copy`, item.formValues)
        .then(() => {
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.$t(
              "notification.instance.copyInstance.completed"
            ),
          });
          this.modalClose();
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },

    // PUSH TO STAGING ACTION
    showPushToStagingModal({ application, databaseDiff, loadingState }) {
      this.resetModal();
      this.modalOptions.title = this.$t("heading.instance.pushToStaging.title");
      this.modalOptions.icon = '$send'
      this.modalOptions.open = true;
      if(loadingState){
        this.modalOptions.customClass = "hide-cancel";
        this.modalOptions.messageLoader = true;
        this.modalOptions.messageTitle = this.$t("heading.instance.pushToLoadingState.messageTitle");
        this.modalOptions.messageTitleClass = 'text-center'
        this.modalOptions.message = this.$t("heading.instance.pushToLoadingState.message");
        this.modalOptions.messageClass = 'text-center'
        this.modalOptions.buttons.unshift({
          label: this.$t("button.close"),
          type: "cancel",
          color: "normal",
          onclick: modal => {
            modal.$emit('modal-close');
          }
        });
      }else{
        this.modalOptions.buttons.unshift({
          label: this.$t("button.pushToStaging"),
          color: 'primary',
          onclick: modal => {
            modal.$refs.form.validate() &&
            this.pushToStaging(application, modal.formValues);
          }
        });
  
        let structureChanges = [];
        for (let table of databaseDiff.tables_structure_diff) {
          structureChanges.push({ label: table, value: table });
        }
        let dataChanges = [];
        for (let table of databaseDiff.tables_data_diff) {
          dataChanges.push({ label: table, value: table });
        }
        this.modalOptions.formFields = [
          {
            label: this.$t("form.label.overwriteFiles"),
            name: "overwrite_files",
            type: "checkbox",
          },
          {
            label: this.$t("form.label.pushDatabase"),
            name: "push_db",
            type: "checkbox",
          },
          {
            label: this.$t("form.label.structuralChangeTables"),
            name: "structural_change_tables",
            tooltip: "tooltip.instance.pushToLive.structuralChangeTables",
            type: "select",
            multiple: true,
            options: structureChanges,
          },
          {
            label: this.$t("form.label.dataChangeTables"),
            name: "datachange_tables",
            tooltip: "tooltip.instance.pushToLive.dataChangeTables",
            type: "select",
            multiple: true,
            options: dataChanges,
          },
        ];
      }
    },
    pushToStaging(application, formValues) {
      if (formValues.overwrite_files === null) {
        formValues.overwrite_files = false;
      }
      if (formValues.push_db === null) {
        formValues.push_db = false;
      }
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      Api.put(
        `/instances/${application.id}/push-to-staging`,
        formValues
      ).then(() => {
        this.$store.dispatch("addAlert", {
          success: true,
          successMessage: this.$t("notification.instance.modal.pushToStaging.success")
        });
        this.modalClose();
        this.$emit('instance-push-to-staging-in-progress', application);
      }).catch(error => {
        this.modalOptions.submittingError = Api.getErrorMessage(error);
        this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: Api.getErrorMessage(error),
        });
      }).finally(() => {
        this.modalOptions.persistent = false;
        this.modalOptions.submitting = false;
      });
    },
  }
}
