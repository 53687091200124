<template>
  <v-menu
    transition="custom-tooltip"
    open-delay="150"
    bottom
    origin="center"
    nudge-left="32px"
    z-index="204"
    offset-overflow
    nudge-bottom="32px"
    min-width="110px"
    content-class="menu-tooltip"
    :open-on-click="false"
    :open-on-focus="false"
    :open-on-hover="true"
    close-delay="0"
    v-model="tooltipOpen"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="{ ...on, click: handleClick, mouseleave: handleLeave }">
        <slot></slot>
      </div>
    </template>
    <div ref="text" class="px-2 py-2 text-center">{{ copyText }}</div>
  </v-menu>
</template>

<script>
export default {
  props: {
    text: String,
  },
  data: function () {
    return {
      tooltipOpen: false,
      copyText: "Click to copy",
    };
  },
  methods: {
    handleLeave: function () {
      this.tooltipOpen = false;
      setTimeout(() => {
        this.copyText = "Click to copy";
      }, 150);
    },
    handleClick: function () {
      this.tooltipOpen = true;

      navigator.clipboard.writeText(this.text);

      this.$nextTick(() => {
        this.copyText = "Copied";
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.copyButton {
  //   pointer-events: none;
}
</style>