<template>
  <span class="d-flex align-center p-4 font-weight-600 cursor-pointer info--text preview-btn">
    <span @click="isOpen = true">{{ $t('button.previewSite') }}</span>
    <preview-site-modal
      :is-open.sync="isOpen"
      :instance="instance"
      @close="isOpen = false"
    />
    <v-icon size="12" class="">$arrowRightUp</v-icon>
    </span>
</template>

<script>
import PreviewSiteModal from "../modal/PreviewSiteModal.vue";

export default {
  name: "PreviewSiteButton",
  components: {PreviewSiteModal},
  props: {
    instance: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      isOpen: false,
    }
  }
}
</script>

<style scoped lang="scss">
    .preview-btn{
        width: 100%;
        column-gap: 2px;
        transition: 0.2s ease;
        cursor: pointer;
        &:hover{
            color: #004FC7 !important;
        }
    }
</style>