<template>
    <v-dialog
      hide-overlay
      :overlay-opacity="0"
      :transition="false"
      persistent
      ref="dialog"
      width="561px"
      :value="isOpen ? true : false"
      absolute
    >
      <div class="card-overlay" @click="$refs.dialog.animateClick()" />
      <v-card style="width: 561px" v-if="isOpen">
        <template>
          <v-card-title class="pb-0 flex justify-space-between align-center">
            <div class="d-flex flex-column">
              <span class="font-weight-light p-1 base--text">
                {{ $t('heading.onboarding.import.url.title') }}
              </span>
              <h4 class>{{ $t('heading.onboarding.newInstance.location.subtitle') }}</h4>
            </div>
            <v-btn x-small icon @click="$emit('removeModal')" class="close-model">
              <v-icon>$close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text height="800px" class="px-10 pt-8">
            <p class="p-3">
              {{ $t("heading.onboarding.newInstance.location.info") }}
            </p>
            
            <v-form ref="form">
                <v-col
                  cols="12"
                  class="py-0 d-flex flex-column"
                >
                  <div v-for="(option, index) in userConfigurableOptions" :key="index">
                  <label class="base--text p-3">{{ $t(`form.label.${option.name}`) }}</label>

                  <div class="d-flex input-group">
                    <v-select
                      v-if="option.type === 'select'"
                      v-model="option.value"
                      item-value="value"
                      item-text="text"
                      :items="option.options"
                      outlined
                      dense
                      required
                      :menu-props="{ offsetY: true }"
                    >
                      <template v-slot:no-data>
                        <loader />
                      </template>
                    </v-select>
                  </div>
                </div>
              </v-col>
            </v-form>
          </v-card-text>

          <v-card-actions class="pt-0 d-flex align-center justify-space-between">
            <div>
              <step-indicator :currentStep="3" :maxSteps="4" />
            </div>
            <div class="d-flex align-center">
              <v-btn large @click="$emit('removeModal')" text elevation="0" class="mr-3">
                <span class="font-weight-bold gray--text text--darken-1">
                  {{ $t('button.back') }}
                </span>
              </v-btn>
              <v-btn large @click="$emit('user-configurable-options-selected', userConfigurableOptionsToForm)" color="primary" elevation="0">
                <span class="font-weight-bold">
                  {{ $t('button.continue') }}
                  <v-icon size="20" class="mr-0">$arrowright</v-icon>
                </span>
              </v-btn>
            </div>
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
  </template>

  <script>
  import StepIndicator from "../StepIndicator.vue";
  import FormRulesMixin from "../../mixins/FormRulesMixin";

  export default {
    mixins: [FormRulesMixin],
    components: {
      StepIndicator,
    },
    props: {
      services: Array,
      selectedService: Object,
      isOpen: [Boolean, String],
    },
    watch: {
      isOpen: function (open) {
        if (open) {
          this.$store.dispatch("lockBodyScroll");
        }
        this.$store.dispatch("unlockBodyScroll");
      },
    },
    computed: {
      userConfigurableOptions() {
        return this.selectedService.onboarding.user_configurable_options;
      },
      userConfigurableOptionsToForm() {
        return this.userConfigurableOptions.reduce((acc, item) => {
          acc[item.name] = item.value;
          return acc;
        }, {});
      }
    }
  };
  </script>
