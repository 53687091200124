<template>
    <svg 
    :width="width"
    :height="height" 
    viewBox="0 0 24 24" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M21.9073 18.1031L16.6975 12.8587C17.3421 11.4342 17.5372 9.84716 17.257 8.30898C16.9767 6.7708 16.2343 5.35456 15.1288 4.249C14.0232 3.14343 12.607 2.40106 11.0688 2.1208C9.53061 1.84054 7.94354 2.03571 6.5191 2.6803L11.3391 7.50028L11.3519 7.5131C11.5868 7.75273 11.7183 8.07489 11.7183 8.41044C11.7183 8.74598 11.5868 9.06815 11.3519 9.30777L9.30084 11.3588C9.06122 11.5937 8.73905 11.7253 8.40351 11.7253C8.06797 11.7253 7.7458 11.5937 7.50617 11.3588L2.67337 6.52603C2.02878 7.95047 1.83361 9.53754 2.11387 11.0757C2.39413 12.6139 3.13651 14.0301 4.24207 15.1357C5.34763 16.2413 6.76387 16.9836 8.30205 17.2639C9.84023 17.5442 11.4273 17.349 12.8517 16.7044L17.9852 22.0253" 
        :stroke="iconColor"
        stroke-width="2" 
        stroke-linecap="round" 
        stroke-linejoin="round"
        />
    </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>

<style>
</style>