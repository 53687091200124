<template>
    <svg 
    :width="width"
    :height="height"
    viewBox="0 0 16 16" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg">
        <path d="M9.50408 4.44383C9.37874 4.57169 9.30854 4.7436 9.30854 4.92265C9.30854 5.1017 9.37874 5.27361 9.50408 5.40147L10.5985 6.49592C10.7264 6.62126 10.8983 6.69146 11.0773 6.69146C11.2564 6.69146 11.4283 6.62126 11.5562 6.49592L14.135 3.91712C14.4789 4.67721 14.5831 5.52407 14.4335 6.34485C14.284 7.16563 13.8878 7.92134 13.2979 8.51127C12.708 9.10121 11.9523 9.49734 11.1315 9.64689C10.3107 9.79644 9.46385 9.69229 8.70376 9.34834L3.9771 14.075C3.70497 14.3471 3.33589 14.5 2.95105 14.5C2.56621 14.5 2.19713 14.3471 1.925 14.075C1.65288 13.8029 1.5 13.4338 1.5 13.0489C1.5 12.6641 1.65288 12.295 1.925 12.0229L6.65166 7.29624C6.30771 6.53615 6.20356 5.68929 6.35311 4.86851C6.50266 4.04774 6.8988 3.29202 7.48873 2.70209C8.07866 2.11216 8.83437 1.71602 9.65515 1.56648C10.4759 1.41693 11.3228 1.52107 12.0829 1.86503L9.51092 4.43699L9.50408 4.44383Z" 
            :stroke="iconColor"
            stroke-linecap="round" 
            stroke-linejoin="round"
        />
    </svg>
  </template>
  
  <script>
  export default {
    props: {
      width: {
        type: [Number, String],
        default: 24,
      },
      height: {
        type: [Number, String],
        default: 24,
      },
      iconColor: {
        type: String,
        default: "currentColor",
      },
    },
  };
  </script>
  
  <style>
  </style>