<template>
  <div>
    <v-menu
      :close-on-content-click="false"
      open-on-hover
      bottom
      :right="left ? undefined : true"
      :left="left ? true : undefined"
      :nudge-right="left ? '-12px' : '12px'"
      close-delay="150"
      open-delay="150"
      offset-x
      min-width="308px"
      max-width="308px"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex align-center">
          <loader
            v-on="on"
            v-bind="attrs"
            v-if="loading"
            :noMargins="true"
            :size="16"
            color="primary"
          />
          <v-icon
            v-else
            v-on="on"
            v-bind="attrs"
            size="16"
            style="line-height: 0px"
            :color="icon.color"
          >
            {{ `$${icon.type}` }}
          </v-icon>
        </div>
      </template>
      <div class="pa-4" v-if="!resolvedCorrectly">
        <div class="d-flex">
          <v-icon color="error" size="20"> $alertcircle</v-icon>
          <p class="error--text px-2 mb-2 p-xs">
            {{ $t("heading.instance.dnsTooltip.desc") }}
          </p>
        </div>
        <v-btn
          color="error"
          class="v-btn--fix-dns ml-7 px-3"
          small
          @click="openDnsModal()"
        >
        <v-icon class="mr-1" color="white" size="20"> $wrenchRight</v-icon>
          <span class="">{{ $t("heading.instance.dnsTooltip.button") }}</span>
        </v-btn>
      </div>
      <div class="pa-5" v-else>
        {{ sslInfo.text }}
      </div>
    </v-menu>
    <v-dialog
      ref="dialog"
      v-model="isOpenDnsModal"
      transition="custom-dialog-transition"
    >
      <div class="card-overlay" @click="openDnsModal()"></div>
      <v-card style="width: 560px">
        <v-card-title class="pb-6 flex justify-space-between align-center">
          <h4 class="font-weight-light">
            {{ $t("heading.instance.modal.setDomain.title") }}
          </h4>
          <v-btn icon x-small @click="openDnsModal()">
            <v-icon size="24">$close</v-icon>
          </v-btn>
        </v-card-title>
          <v-card-text class="pb-8">
            <DnsOptions
              :domain="instance.domain"
              :onboardingSubdomain="instance.onboarding_subdomain"
              :dnsSettings="instance.dns_settings"
              :ipv4Addresses="instance.host_ipv4_addresses"
              :hasDnsServer="instance.service_has_dns"
              :nameservers="instance.dns_nameservers"
            />
          </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Api from "@/apis/Api";
import Loader from "../Loader.vue";
import DnsOptions from "../DnsOptions.vue";
import moment from "moment";

export default {
  components: {
    Loader,
    DnsOptions,
  },
  props: {
    instance: Object,
    hasText: Boolean,
    left: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      isOpenDnsModal: false,
      loading: true,
      resolvedCorrectly: true,
      btnDisabled: false,
      throttleTime: 5000,
    };
  },
  computed: {
    ssl() {
      if (
        !this.instance.domain_ssl_details ||
        !this.instance.domain_ssl_details.detected_ssl_cert ||
        !this.instance.domain_ssl_details.detected_ssl_cert.not_before
      ) {
        return {
          certificate_installed: false,
          certificate: null,
        };
      }
      return {
        certificate_installed: true,
        certificate: this.instance.domain_ssl_details.detected_ssl_cert,
      };
    },
    sslInfo() {
      if (this.loading) {
        return {
          text: this.$t("tooltip.dnsIndicator.fetching", {
            url: this.instance.url,
          }),
        };
      }
      if (!this.ssl.certificate_installed) {
        return { text: this.$t("tooltip.dnsIndicator.notSsl") };
      }
      if (this.ssl.certificate && this.ssl.certificate.self_signed) {
        return {
          text: this.$t("tooltip.dnsIndicator.selfSigned"),
        };
      }
      if (this.ssl.certificate && !this.ssl.certificate.name_match) {
        return {
          text: this.$t("tooltip.dnsIndicator.sslNotMatched"),
        };
      }
      return { text: this.$t("tooltip.dnsIndicator.secure") };
    },
    icon() {
      if (!this.resolvedCorrectly) {
        return { type: "alertcircle", color: "warning" };
      }
      if (!this.ssl.certificate_installed) {
        return { type: "unlock", color: "error" };
      }
      if (this.ssl.certificate && this.ssl.certificate.self_signed) {
        return { type: "lock", color: "error" };
      }
      if (this.ssl.certificate && !this.ssl.certificate.name_match) {
        return { type: "unlock", color: "error" };
      }
      return { type: "lock", color: "success" };
    },
    instanceAge() {
      return moment().diff(moment(this.instance.created_at)) / 1000 / 3600;
    },
  },
  mounted() {
    if (!this.instance.host_ip_address) {
      this.loading = false;
      this.resolvedCorrectly = false;
      return;
    }
    this.getInfo();
  },
  methods: {
    openDnsModal() {
      this.isOpenDnsModal = !this.isOpenDnsModal;
    },
    async getInfo() {
      if (!this.instance.isActive) return;

      await this.refreshSsl();
      if (this.ssl.certificate_installed === false) {
        await this.checkDnsPropagation();
      }
      this.loading = false;
    },
    async refreshSsl() {
      if (this.instance.domain_ssl_details === null) {
        return;
      }
      if (this.instanceAge < 1) {
        await this.getDetectedSslCertificate();
        return;
      }
      let certMaxCacheMinutes = 2880;
      if (this.instanceAge < 24) {
        certMaxCacheMinutes = 5;
      }
      let now = moment();
      let detected = moment.unix(
        this.instance.domain_ssl_details?.detected_ssl_cert_checked_time ?? null
      );
      if (now.diff(detected) / 1000 / 60 > certMaxCacheMinutes) {
        await this.getDetectedSslCertificate();
      }
    },
    async getDetectedSslCertificate() {
      await Api.hosting(this.instance.hostingId)
        .getDetectedCertificate(this.instance.domain, true)
        .then((response) => {
          this.instance.setDetectedSslCert(response);
        })
        .catch((/*error*/) => {
          // this.$store.dispatch("addAlert", {
          //   success: false,
          //   errorMessage: Api.getErrorMessage(error),
          // });
        });
    },
    async checkDnsPropagation() {
      this.resolvedCorrectly = await Api.dns().verifyDomainResolving(
        this.instance.domain,
        this.instance.onboarding_subdomain,
        this.instance.dns_settings,
        this.instance.service_has_dns,
        this.instance.host_ipv4_addresses,
        this.instance.dns_nameservers
      );
      if (!this.resolvedCorrectly) {
        this.$emit("dns-propagation-in-progress");
      }
    },
  },
  watch: {
    isOpenDnsModal: function (value) {
      value
        ? this.$store.dispatch("lockBodyScroll")
        : this.$store.dispatch("unlockBodyScroll");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn.v-btn--fix-dns{
  box-shadow: none;
}
.dns-indicator {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: transparent;
  position: relative;

  &:before {
    position: absolute;
    content: "";
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: var(--v-gray-lighten3);
    top: calc(50% - 7px);
    left: calc(50% - 7px);
    border: 3px solid var(--v-gray-lighten5);
  }

  &--online {
    &:before {
      background-color: var(--v-primary-base);
      border: 3px solid var(--v-primary-lighten5);
    }
  }

  &--offline {
    &:before {
      background-color: var(--v-error-base);
      border: 3px solid var(--v-error-lighten5);
    }
  }
}
.nameserver-list {
  padding: 0px;
  list-style: none;

  .list-item {
    position: relative;
    padding-left: 14px;
    font-weight: 400;
    font-size: var(--font-size-medium);
    display: flex;
    line-height: 25px;
    color: var(--v-gray-darken4);

    + .list-item {
      margin-top: 4px;
    }

    &::before {
      content: "";
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      left: 0px;
      top: calc(50% - 3px);
      background: var(--v-primary-base);
    }
  }

  &--error {
    .list-item {
      &::before {
        background: var(--v-error-base);
      }
    }
  }
}

.list-container {
  + .list-container {
    margin-top: 16px;
  }

  &__title {
    display: inline-block;
    font-weight: 700;
    line-height: 26px;
    font-size: var(--font-size-base);
    color: var(--v-gray-darken4);
    margin-bottom: 8px;
  }
}
</style>
