// SOCIALS
import FacebookSocialIcon from "../components/icons/socials/Facebook.vue";
import FacebookWhiteSocialIcon from "../components/icons/socials/FacebookWhite.vue";
import GoogleSocialIcon from "../components/icons/socials/Google.vue";
import LinkedinSocialIcon from "../components/icons/socials/Linkedin.vue";
import AppleSocialIcon from "../components/icons/socials/Apple.vue";
import MicrosoftSocialIcon from "../components/icons/socials/Microsoft.vue";
import GithubSocialIcon from "../components/icons/socials/Github.vue";
//
import LoadingHourglassIcon from '../components/icons/loading-hourglass-icon.vue'
import UserIcon from '../components/icons/user.vue'
import LoaderIcon from '../components/icons/loader-icon.vue'
import BellIcon from '../components/icons/bell.vue'
import CreditCardIcon from '../components/icons/credit-card.vue'
import WrenchRight from '../components/icons/wrench-right.vue'
import LogoutIcon from '../components/icons/logout.vue'
import BandwidthIcon from '../components/icons/bandwidth.vue'
import GridIcon from '../components/icons/grid.vue'
import ListIcon from '../components/icons/list.vue'
import PlusIcon from "../components/icons/plus.vue"
import SearchIcon from "../components/icons/search.vue"
import StorageIcon from "../components/icons/storage.vue"
import SettingsIcon from "../components/icons/settings.vue"
import WrenchIcon from "../components/icons/wrench.vue"
import UpdateIcon from "../components/icons/update.vue"
import MoreHorizontalIcon from "../components/icons/more-horizontal.vue"
import RestoreIcon from "../components/icons/restore.vue"
import CopyIcon from "../components/icons/copy.vue"
import ThrashIcon from "../components/icons/thrash.vue"
import SlidersIcon from "../components/icons/sliders.vue"
import ActivateIcon from "../components/icons/activate.vue"
import DisableIcon from "../components/icons/disable.vue"
import BackupRestoreIcon from '../components/icons/backup-restore.vue'
import BackupIcon from "../components/icons/backup.vue"
import ArrowLeftIcon from "../components/icons/arrow-left.vue"
import ArrowRightIcon from "../components/icons/arrow-right.vue"
import LockIcon from '../components/icons/lock.vue'
import ExternalLinkIcon from '../components/icons/externallink.vue'
import CpuIcon from '../components/icons/cpu.vue'
import CheckIcon from '../components/icons/check.vue'
import HelpIcon from '../components/icons/help.vue'
import DecreaseIcon from '../components/icons/decrease.vue'
import IncreaseIcon from '../components/icons/increase.vue'
import AlertOkIcon from "../components/icons/alert-ok.vue"
import AlertWarningIcon from "../components/icons/alert-warning.vue"
import ChevronDownIcon from "../components/icons/chevron-down.vue"
import EyeClosedIcon from "../components/icons/eye-closed.vue"
import PlusCircleIcon from "../components/icons/plus-circle.vue"
import EyeIcon from "../components/icons/eye.vue"
import ArrowDown from "../components/icons/arrow-down.vue"
import ArrowUp from "../components/icons/arrow-up.vue"
import BarChartIcon from "../components/icons/bar-chart.vue"
import CalendarIcon from "../components/icons/calendar.vue"
import ChevronLeft from "../components/icons/chevron-left.vue"
import ChevronRight from "../components/icons/chevron-right.vue"
import ChevronsRight from "../components/icons/chevrons-right.vue"
import DatabaseIcon from "../components/icons/database.vue"
import DollarSignIcon from "../components/icons/dollar-sign.vue"
import DownloadCloudIcon from "../components/icons/download-cloud.vue"
import EditIcon from "../components/icons/edit.vue"
import Edit2Icon from "../components/icons/edit2.vue"
import FileTextIcon from "../components/icons/file-text.vue"
import HomeIcon from "../components/icons/home.vue"
import ImageIcon from "../components/icons/image.vue"
import InstalledIcon from "../components/icons/installed.vue"
import MenuIcon from "../components/icons/menu.vue"
import MessageCircleIcon from "../components/icons/message-circle.vue"
import Minus from "../components/icons/minus.vue"
import MoreVerticalIcon from "../components/icons/more-vertical.vue"
import PowerIcon from "../components/icons/power.vue"
import RamIcon from "../components/icons/ram.vue"
import UnlockIcon from "../components/icons/unlock.vue"
import XCircle from "../components/icons/x-circle.vue"
import CheckCircleIcon from "../components/icons/check-circle.vue"
import AlertError from '../components/icons/alert-error.vue'
import WordpressIcon from '../components/icons/wordpress.vue'
import CloseIcon from '../components/icons/close.vue'
import ArrowsSort from '../components/icons/arrows-sort.vue'
import OKCircleIcon from '../components/icons/ok-circle.vue'
import PaginationLeft from '../components/icons/pagination-left.vue'
import PaginationRight from '../components/icons/pagination-right.vue'
import AlertWarningFilledIcon from '../components/icons/alert-warning-filled.vue'
import EmailIcon from "../components/icons/email.vue"
import LockOutlineIcon from "../components/icons/lock-outline.vue"
import ClockIcon from "../components/icons/clock.vue"
import MailIcon from "../components/icons/mail.vue"
import TwitterIcon from "../components/icons/twitter.vue"
import FbIcon from "../components/icons/fb.vue"
import SendIcon from "../components/icons/send.vue"
import AlertCircle from "../components/icons/alert-circle.vue"
import WordpressAltIcon from "../components/icons/wordpress-alternate.vue"
import ShareIcon from "../components/icons/share-icon.vue"
import Share2Icon from "../components/icons/share2-icon.vue"
import HourglassIcon from "../components/icons/hourglass.vue"
import KeyIcon from "../components/icons/key.vue"
import DiskSpaceIcon from "../components/icons/diskspaceIcon.vue"
import EmailAccountsIcon from "../components/icons/emailaccountsIcon.vue"
import InfiniteIcon from "../components/icons/infiniteIcon.vue"
import BandwidthLimitIcon from "../components/icons/bandwidthIcon.vue"
import FtpAccountsIcon from "../components/icons/ftpaccountsIcon.vue"
import MySqlDatabasesIcon from "../components/icons/mysqldatabasesIcon.vue"
import SubDomainsIcon from "../components/icons/subdomainsIcon.vue"
import AddonDomainsIcon from "../components/icons/addondomainsIcon.vue"
import InstallIcon from "../components/icons/install.vue"
import StarIcon from "../components/icons/star"
import StarFullIcon from "../components/icons/star-full.vue"
import StarFullPremiumIcon from "../components/icons/star-full-premium.vue"
import StarEmptyIcon from "../components/icons/star-empty.vue"
import ArrowCornerRightIcon from "../components/icons/arrow-corner-right.vue"
import PluginIcon from "../components/icons/pluginIcon.vue"
import SuspendedIcon from "../components/icons/suspendedIcon.vue"
import GlobeIcon from "../components/icons/globe.vue"
import ProxyOnIcon from "../components/icons/proxy-on.vue";
import ProxyOffIcon from "../components/icons/proxy-off.vue";
import InfoCircle from "../components/icons/info-circle.vue";
import ErrorCircle from "../components/illustrations/preview-error-icon-sm.vue";
import ArrowRightUp from "../components/icons/arrow-right-up.vue";
import MergeIcon from "../components/icons/merge.vue";

// DEVICES

import LinuxIcon from "../components/icons/linux.vue"
import WindowsIcon from "../components/icons/windows.vue"
import MacOsIcon from "../components/icons/mac.vue"
import AndroidIcon from "../components/icons/android.vue"
import DeviceOtherIcon from "../components/icons/device-other.vue"

import ChromeIcon from "../components/icons/chrome.vue"
import FirefoxIcon from "../components/icons/firefox.vue"
import OperaIcon from "../components/icons/opera.vue"
import EdgeIcon from "../components/icons/edge.vue"
import SafariIcon from "../components/icons/safari.vue"
import BrowserOtherIcon from "../components/icons/browser-other.vue"
import Preview from "../components/icons/preview.vue";
import PreviewDetails from "../components/icons/preview-details.vue";
import ModalIcon from "../components/icons/modal-icon.vue";


// 
const iconsSettings = {
    values: {
        loadingHourglass: {
            component: LoadingHourglassIcon,
        },
        user: {
            component: UserIcon,
        },
        loader: {
            component: LoaderIcon,
        },
        suspended: {
            component: SuspendedIcon,
        },
        bell: {
            component: BellIcon,
        },
        creditcard: {
            component: CreditCardIcon,
        },
        logout: {
            component: LogoutIcon,
        },
        errorCircle: {
            component: ErrorCircle,
        },
        bandwidth: {
            component: BandwidthIcon,
        },
        grid: {
            component: GridIcon,
        },
        list: {
            component: ListIcon,
        },
        plus: {
            component: PlusIcon,
        },
        search: {
            component: SearchIcon,
        },
        storage: {
            component: StorageIcon,
        },
        settings: {
            component: SettingsIcon,
        },
        wrench: {
            component: WrenchIcon,
        },
        update: {
            component: UpdateIcon,
        },
        morehorizontal: {
            component: MoreHorizontalIcon,
        },
        restore: {
            component: RestoreIcon,
        },
        copy: {
            component: CopyIcon,
        },
        thrash: {
            component: ThrashIcon,
        },
        sliders: {
            component: SlidersIcon,
        },
        activate: {
            component: ActivateIcon
        },
        disable: {
            component: DisableIcon
        },
        restorebackup: {
            component: BackupRestoreIcon
        },
        backup: {
            component: BackupIcon
        },
        increase: {
            component: IncreaseIcon
        },
        decrease: {
            component: DecreaseIcon
        },
        arrowleft: {
            component: ArrowLeftIcon
        },
        arrowright: {
            component: ArrowRightIcon
        },
        lock: {
            component: LockIcon
        },
        externallink: {
            component: ExternalLinkIcon
        },
        cpu: {
            component: CpuIcon
        },
        alertok: {
            component: AlertOkIcon,
        },
        alertwarning: {
            component: AlertWarningIcon,
        },
        check: {
            component: CheckIcon
        },
        help: {
            component: HelpIcon
        },
        chevrondown: {
            component: ChevronDownIcon
        },
        dropdown: {
            component: ChevronDownIcon
        },
        eyeclosed: {
            component: EyeClosedIcon
        },
        pluscircle: {
            component: PlusCircleIcon
        },
        eye: {
            component: EyeIcon
        },
        arrowdown: {
            component: ArrowDown
        },
        arrowup: {
            component: ArrowUp
        },
        barchart: {
            component: BarChartIcon
        },
        calendar: {
            component: CalendarIcon
        },
        chevronleft: {
            component: ChevronLeft
        },
        chevronright: {
            component: ChevronRight
        },
        unfold: {
            component: ChevronRight
        },
        chevronsright: {
            component: ChevronsRight
        },
        database: {
            component: DatabaseIcon
        },
        dollarsign: {
            component: DollarSignIcon
        },
        downloadcloud: {
            component: DownloadCloudIcon
        },
        edit: {
            component: EditIcon
        },
        edit2: {
            component: Edit2Icon
        },
        filetext: {
            component: FileTextIcon
        },
        home: {
            component: HomeIcon
        },
        image: {
            component: ImageIcon
        },
        installed: {
            component: InstalledIcon
        },
        menu: {
            component: MenuIcon
        },
        messagecircle: {
            component: MessageCircleIcon
        },
        minus: {
            component: Minus
        },
        morevertical: {
            component: MoreVerticalIcon
        },
        power: {
            component: PowerIcon
        },
        ram: {
            component: RamIcon
        },
        unlock: {
            component: UnlockIcon
        },
        xcircle: {
            component: XCircle
        },
        checkcircle: {
            component: CheckCircleIcon
        },
        alerterror: {
            component: AlertError
        },
        wordpress: {
            component: WordpressIcon
        },
        close: {
            component: CloseIcon
        },
        checkboxOn: {
            component: CheckIcon
        },
        arrowsSort: {
            component: ArrowsSort
        },
        okcircle: {
            component: OKCircleIcon
        },
        paginationleft: {
            component: PaginationLeft
        },
        paginationright: {
            component: PaginationRight
        },
        alertwarningfilled: {
            component: AlertWarningFilledIcon
        },
        email: {
            component: EmailIcon,
        },
        lockoutline: {
            component: LockOutlineIcon
        },
        clock: {
            component: ClockIcon
        },
        mail: {
            component: MailIcon
        },
        twitter: {
            component: TwitterIcon
        },
        fb: {
            component: FbIcon
        },
        send: {
            component: SendIcon
        },
        alertcircle: {
            component: AlertCircle
        },
        wordpressalt: {
            component: WordpressAltIcon
        },
        shareIcon: {
            component: ShareIcon
        },
        share2Icon: {
            component: Share2Icon
        },
        hourglassIcon: {
            component: HourglassIcon
        },
        keyIcon: {
            component: KeyIcon
        },
        diskspaceIcon: {
            component: DiskSpaceIcon
        },
        emailaccountsIcon: {
            component: EmailAccountsIcon
        },
        infiniteIcon: {
            component: InfiniteIcon
        },
        bandwidthIcon: {
            component: BandwidthLimitIcon
        },
        ftpaccountsIcon: {
            component: FtpAccountsIcon
        },
        mysqldatabasesIcon: {
            component: MySqlDatabasesIcon
        },
        subdomainsIcon: {
            component: SubDomainsIcon
        },
        addondomainsIcon: {
            component: AddonDomainsIcon
        },


        // 
        linuxIcon: {
            component: LinuxIcon
        },
        windowsIcon: {
            component: WindowsIcon
        },
        macOsIcon: {
            component: MacOsIcon
        },
        androidIcon: {
            component: AndroidIcon
        },
        deviceOtherIcon: {
            component: DeviceOtherIcon
        },

        chromeIcon: {
            component: ChromeIcon
        },
        firefoxIcon: {
            component: FirefoxIcon
        },
        operaIcon: {
            component: OperaIcon
        },
        edgeIcon: {
            component: EdgeIcon
        },
        safariIcon: {
            component: SafariIcon
        },
        browserOtherIcon: {
            component: BrowserOtherIcon
        },
        installIcon: {
            component: InstallIcon
        },
        starIcon: {
            component: StarIcon
        },
        starFullIcon: {
            component: StarFullIcon
        },
        starFullPremiumIcon: {
            component: StarFullPremiumIcon
        },
        starEmptyIcon: {
            component: StarEmptyIcon
        },
        arrowCornerRightIcon: {
            component: ArrowCornerRightIcon
        },
        pluginIcon: {
            component: PluginIcon
        },
        globe: {
            component: GlobeIcon
        },
        proxyOn: {
            component: ProxyOnIcon
        },
        proxyOff: {
            component: ProxyOffIcon
        },
        infoCircle: {
            component: InfoCircle
        },
        preview: {
            component: Preview
        },
        previewDetails: {
            component: PreviewDetails
        },
        wrenchRight: {
            component: WrenchRight
        },
        arrowRightUp: {
            component: ArrowRightUp
        },
        modalIcon: {
            component: ModalIcon
        },
        merge: {
            component: MergeIcon
        },
        facebook: {
            component: FacebookSocialIcon
        },
        facebookWhite: {
            component: FacebookWhiteSocialIcon
        },
        google: {
            component: GoogleSocialIcon
        },
        linkedin: {
            component: LinkedinSocialIcon
        },
        apple: {
            component: AppleSocialIcon
        },
        microsoft: {
            component: MicrosoftSocialIcon
        },
        github: {
            component: GithubSocialIcon
        }
    }
}

export default iconsSettings