<template>
  <div class="notifications-empty">
    <svg
      class="notifications-empty__img"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M47.637 35.1718C48.6436 30.9365 47.907 26.4808 45.6237 22.7841C43.3403 19.0873 39.6574 16.4433 35.4098 15.4396C31.1623 14.4358 26.6937 15.1703 22.9863 17.4471C19.2789 19.7239 16.6272 23.3961 15.6206 27.6314C11.2257 46.2375 1.96944 49.6895 1.96944 49.6895L49.9694 61C49.9694 61 43.2175 53.8024 47.637 35.1718V35.1718Z"
        stroke="#B0B0D3"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M29.9694 57.2275C29 60.5 26.9221 61.48 24.4889 60.8725C22.0558 60.265 20.5203 57.6072 21.0872 55"
        fill="#EEEEF4"
      />
      <path
        d="M29.9694 57.2275C29 60.5 26.9221 61.48 24.4889 60.8725C22.0558 60.265 20.5203 57.6072 21.0872 55"
        stroke="#797997"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M27.6923 34.1661L31.9015 40.96"
        stroke="#797997"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M33.2062 35.4708L26.3877 39.6554"
        stroke="#797997"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M46 2.98859H42"
        stroke="#B0B0D3"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M44.012 5V1"
        stroke="#B0B0D3"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M55.9694 15C57.074 15 57.9694 14.1046 57.9694 13C57.9694 11.8954 57.074 11 55.9694 11C54.8649 11 53.9694 11.8954 53.9694 13C53.9694 14.1046 54.8649 15 55.9694 15Z"
        stroke="#797997"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
    </svg>

    <div class="notifications-empty__text">
      <p class="p-3 font-weight-bold">{{ $t('message.emptyTable.notification.title') }}</p>
      <p class="p-3 text-center mt-1">{{ $t('message.emptyTable.notification.description') }}</p>
    </div>
  </div>
</template>

<script>
// import EmptyImage from "../../assets/img/happy-potato.vue";

export default {
  // components: {
  //   EmptyImage,
  // },
};
</script>


<style lang="scss" scoped>
.notifications-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 306px;
  height: 217px;
  padding: 16px;
  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      margin-bottom: 0px;
      color: map-get($text, heading);
    }
  }
  &__img {
    margin-bottom: 16px;
  }
}
</style>